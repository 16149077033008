@import '../Footer.config';
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

.navLinks {
  display: block;
  flex-direction: row;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 22.4px;
  margin: 0 auto;
  max-width: 1150px;
  width: 100%;
  padding: 46px 25px 35px 35px;

  @media #{$istock-small-up} {
    display: flex;
    padding: 0;
  }

  a {
    color: $istock-footer-link-color;
  }

  a:hover,
  a:focus {
    color: $istock-footer-link-hover-color;
  }
}

.categoryTitle {
  color: $istock-footer-catagory-color;
  font-size: 0.8125rem;
  padding-bottom: 14px;
  line-height: 24px;
  text-transform: uppercase;

  @media #{$istock-small-up} {
    display: none;
  }
}

.navLinks li {
  @media #{$istock-small-up} {
    padding-top: 40px;
    padding-right: 40px;
  }
}

.checkoutFooter p {
  line-height: 18px;
  margin-bottom: 0;
}
