@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_functions';

// Update to generics if getty is same color
$istock-localization-hover-color: $white;
$istock-localization-bg-color: $shale-grey;
$istock-localization-border-color: rgba($istock-localization-hover-color, 0.2);
$istock-footer-bg-color: #181a1a;
$istock-footer-catagory-color: hsl(0deg 0% 100% / 60%);
$istock-footer-link-color: hsl(0deg 0% 100% / 80%);
$istock-footer-link-hover-color: $green;
$istock-footer-text-color: $light-grey;
$istock-modal-text-color: $dark-grey;
$istock-modal-bg-color: $some-grey;
$istock-modal-button-border-color: $some-grey-border;
$istock-link-hover-color: $green;
