@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

.minimalFooter {
  background-color: rgba($dark-grey, 0.7);
  bottom: 0;
  height: auto;
  left: 0;
  position: relative;
  right: 0;
  text-align: center;
}

.copyright {
  color: rgba($white, 0.6);
  display: inline-block;
  font-size: 11px;
  line-height: 12px;
  margin: 0;
  padding: 24px 14px;

  span {
    white-space: nowrap;
  }
}
