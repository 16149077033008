@import '../Footer.config';
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

.legalBlock {
  width: 100%;
  color: #a8b2b1;
  font-size: 0.75rem;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  padding: 25px;
  padding-top: 10px;
}

.legalBlock hr {
  border: 1px solid #353838;
  border-top-color: #000;
  margin: 20px 0;
  width: 94%;
  margin-left: 30px;
  margin-top: 10px;
}

.legalBlock p {
  line-height: 18px;
  padding: 0 25px;
}

.legalBlock a {
  color: #a8b2b1;
  text-decoration: underline;
}

.checkoutFooter .legalBlock p {
  white-space: pre-line;
}

.legalBlock .footerCopy {
  margin-bottom: 5px;
  padding-left: 30px;
  padding-right: 25px;
}
