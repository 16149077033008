@import '../Footer.config';

@mixin footer-button {
  border-color: $istock-localization-border-color;
  color: $istock-footer-text-color;
  padding: 8px;
  background: transparent;
  margin-bottom: 8px;
  max-width: 85%;

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    color: $istock-localization-hover-color;
    background: $istock-localization-bg-color;
  }
}

.localeButton {
  @include footer-button;

  justify-content: flex-start;

  .currentFlag {
    width: 30px;
    height: 20px;
    margin-bottom: -2px;
  }

  .countryName {
    margin-left: 8px;
  }
}

.languageButton {
  @include footer-button;

  justify-content: space-between;

  .languageArrow {
    background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/pagination-next_arrow_default.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 13px;
    width: 13px;
  }

  &:active,
  &:focus,
  &:hover {
    .languageArrow {
      background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/pagination-next_arrow_white.svg');
    }
  }
}
