@import '../Footer.config';
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

.socialLinks {
  width: 150px;
  height: 40px;
  margin-top: 1px;
}

.socialLinks ul {
  margin-bottom: 0;
}

.socialLinks ul li {
  display: inline;
}

.socialLinks a {
  background-position: 50%;
  background-repeat: no-repeat;
  height: 41px;
  margin-right: 4px;
  width: 45px;
  background-size: cover;
  display: inline-block;
  border-radius: 5px;

  @media #{$istock-large-up} {
    size: 60px 60px;
  }
}

.socialLinks .fb {
  background-image: url('./../icons/icon_facebook.svg');
}

.socialLinks .fb:hover,
.socialLinks .fb:focus {
  background-image: url('./../icons/icon_facebook_hover.svg');
}

.socialLinks .tw {
  background-image: url('./../icons/icon_twitter.svg');
}

.socialLinks .tw:hover,
.socialLinks .tw:focus {
  background-image: url('./../icons/icon_twitter_hover.svg');
}

.socialLinks .ig {
  background-image: url('./../icons/icon_instagram.svg');
}

.socialLinks .ig:hover,
.socialLinks .ig:focus {
  background-image: url('./../icons/icon_instagram_hover.svg');
}
