@import '../Footer.config';
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

.navLinks {
  font-size: 0.875rem;
  line-height: 18px;
  margin: 0 auto;
  max-width: 1150px;
  padding: 46px 25px 35px 35px;
  width: 100%;
  height: 280px;
}

.navBlockLocale {
  float: left;
  margin-left: 0;
  margin-right: 3%;
  padding-left: 1.5625%;
  padding-right: 1.5625%;
  width: 100%;

  @media #{$istock-large-up} {
    width: 25%;
  }
}

.navBlock {
  float: left;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.5625%;
  padding-right: 1.5625%;
  width: 100%;

  @media #{$istock-large-up} {
    width: 16.6667%;
  }
}
