@import '../Footer.config';
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

.navBlock {
  float: left;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.5625%;
  padding-right: 1.5625%;
  padding-bottom: 14px;
  width: 100%;

  @media #{$istock-large-up} {
    width: 16.6667%;
  }
}

.navBlock:last-child {
  float: left;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.5625%;
  padding-right: 1.5625%;
  width: 100%;

  @media #{$istock-large-up} {
    width: 16.6667%;
  }
}

.navBlock ul li {
  font-size: 0.875rem;
  line-height: 1.6;
}

.navBlock ul li a {
  color: $istock-footer-link-color;
}

.navBlock ul li a:hover,
.navBlock ul li a:focus {
  color: $istock-footer-link-hover-color;
}

.navBlock .categoryTitle {
  color: $istock-footer-catagory-color;
  font-size: 0.8125rem;
  padding-bottom: 14px;
  line-height: 24px;
  text-transform: uppercase;
}
